
import { Component, Vue, Prop } from "vue-property-decorator";
import { FormFile } from "@/components/forms/index";

@Component({
    components: {
        FormFile,
        documentCard: () => import("@/components/document/card.vue"),
    },
})
export default class DocumentFolder extends Vue {
    @Prop() folder!: DocumentFolder;
    @Prop({ default: 0 }) fileCount!: number;
    @Prop({ default: "Hubr" }) theme!: "Hubr" | "My Hubr";
    @Prop() mode!: "partner" | "admin" | "client";
}
